/**
 * Compresses and converts an image file to base64 while preserving transparency for PNG files
 * @param file The image file to compress
 * @param maxWidth Maximum width for the compressed image
 * @param maxHeight Maximum height for the compressed image
 * @param quality Compression quality (0-1) for JPEG files
 * @returns Promise resolving to base64 string of the compressed image
 */
export const compressAndConvertToBase64 = async (
  file: File, 
  maxWidth = 800, 
  maxHeight = 600, 
  quality = 0.7
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target?.result as string;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        
        // Calculate dimensions while maintaining aspect ratio
        let width = img.width;
        let height = img.height;
        
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }
        
        // Set canvas dimensions
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        
        // Important: clear the canvas with transparent background
        ctx?.clearRect(0, 0, width, height);
        
        // Draw the image
        ctx?.drawImage(img, 0, 0, width, height);
        
        // Determine if the image is PNG with transparency
        const isPNG = file.type === 'image/png';
        
        // Use appropriate format and quality
        const base64 = isPNG 
          ? canvas.toDataURL('image/png') // PNG to preserve transparency
          : canvas.toDataURL('image/jpeg', quality); // JPEG with quality setting
        
        resolve(base64);
      };
      img.onerror = reject;
    };
    reader.onerror = reject;
  });
};

/**
 * Extracts image dimensions from a base64 string
 * @param base64Image Base64 encoded image string
 * @returns Promise resolving to {width, height} object
 */
export const getImageDimensionsFromBase64 = (base64Image: string): Promise<{width: number, height: number}> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height
      });
    };
    img.onerror = reject;
    img.src = base64Image;
  });
};
import React, { useState, useCallback, useEffect } from 'react';
import { 
  Box, Typography, CircularProgress, Accordion, AccordionSummary,
  AccordionDetails, Avatar, Chip, IconButton, Tooltip, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer
} from '@mui/material';
import { 
  ExpandMore, Visibility, Done, Close, DateRange,
  UnfoldLess, UnfoldMore
} from '@mui/icons-material';
import { format, formatDistanceToNow } from 'date-fns';
import { GroupedSubmissions } from '../types';
import { formatDate } from '../services/approvalUtils';
import EmptyStateMessage from './EmptyStateMessage';

interface ApprovalSubmissionsListProps {
  status: string;
  groupedSubmissions: GroupedSubmissions[];
  loading: boolean;
  refreshing: boolean;
  expandedFormId: string | false;
  onExpandForm: (formId: string) => void;
  onViewDetails: (submissionId: string) => void;
  onApprove?: (submission: any) => void;
  onReject?: (submission: any) => void;
  onLoadMore?: (formId: string, page: number) => void;
  navigate: (path: string) => void;
  theme: any;
  isMobile: boolean;
  users: Array<{id: string, email: string, name: string}>;
  selectedUserId: string;
  userRole: string | null;
}

const ApprovalSubmissionsList: React.FC<ApprovalSubmissionsListProps> = ({
  status,
  groupedSubmissions,
  loading,
  refreshing,
  expandedFormId,
  onExpandForm,
  onViewDetails,
  onApprove,
  onReject,
  onLoadMore,
  navigate,
  theme,
  isMobile,
  users,
  selectedUserId,
  userRole
}) => {
  const [minimizedForms, setMinimizedForms] = useState<Record<string, boolean>>({});
  const [formPages, setFormPages] = useState<Record<string, number>>({});

  useEffect(() => {
    const newFormPages = { ...formPages };
    let updated = false;

    Object.keys(newFormPages).forEach(formId => {
      if (!groupedSubmissions.some(g => g.formId === formId)) {
        delete newFormPages[formId];
        updated = true;
      }
    });

    groupedSubmissions.forEach(group => {
      if (!newFormPages[group.formId]) {
        newFormPages[group.formId] = 1;
        updated = true;
      }
    });

    if (updated) {
      setFormPages(newFormPages);
    }
  }, [groupedSubmissions, formPages]);

  const toggleMinimized = useCallback((formId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    setMinimizedForms(prev => ({
      ...prev,
      [formId]: !prev[formId]
    }));
  }, []);

  const isFormMinimized = useCallback((formId: string) => 
    Boolean(minimizedForms[formId]), 
  [minimizedForms]);

  const getFormPage = useCallback((formId: string) => {
    return formPages[formId] || 1;
  }, [formPages]);

  const handleLoadMore = useCallback((formId: string) => {
    const currentPage = getFormPage(formId);
    const nextPage = currentPage + 1;
    
    setFormPages(prev => ({
      ...prev,
      [formId]: nextPage
    }));

    if (onLoadMore) {
      onLoadMore(formId, nextPage);
    }
  }, [onLoadMore, getFormPage]);

  const getVisibleSubmissions = useCallback((group: GroupedSubmissions) => {
    return group.submissions;
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (refreshing) {
    return (
      <Box sx={{ position: 'relative' }}>
        <Box sx={{ 
          position: 'absolute', 
          top: 0, 
          left: 0, 
          right: 0, 
          bottom: 0, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          bgcolor: 'rgba(255, 255, 255, 0.7)',
          zIndex: 1
        }}>
          <CircularProgress />
        </Box>
        
        {renderSubmissionsContent()}
      </Box>
    );
  }

  return renderSubmissionsContent();

  function renderSubmissionsContent() {
    if (groupedSubmissions.length === 0) {
      return <EmptyStateMessage status={status} />;
    }

    return (
      <Box sx={{ p: { xs: 0, sm: 2 } }}>
        {groupedSubmissions.map((group) => (
          <Accordion 
            key={group.formId}
            expanded={expandedFormId === group.formId}
            onChange={() => onExpandForm(group.formId)}
            sx={{ 
              mb: 2,
              boxShadow: 'none',
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: '8px !important',
              '&:before': { display: 'none' },
              overflow: 'hidden'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{ 
                bgcolor: expandedFormId === group.formId 
                  ? `${theme.palette.primary.main}08`
                  : theme.palette.background.paper
              }}
            >
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'space-between',
                width: '100%'
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {group.formLogo ? (
                    <Avatar 
                      src={group.formLogo} 
                      alt={group.formTitle}
                      variant="rounded"
                      sx={{ mr: 2, width: 40, height: 40 }}
                    />
                  ) : (
                    <Avatar 
                      sx={{ mr: 2, bgcolor: theme.palette.primary.main }}
                      variant="rounded"
                    >
                      {group.formTitle.charAt(0)}
                    </Avatar>
                  )}
                  <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {group.formTitle}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {group.totalCount} {status} submission{group.totalCount !== 1 ? 's' : ''}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {expandedFormId === group.formId && (
                    <Tooltip title={isFormMinimized(group.formId) ? "Expand Form" : "Minimize Form"}>
                      <IconButton
                        size="small"
                        className="minimize-button"
                        onClick={(e) => toggleMinimized(group.formId, e)}
                        onMouseDown={(e) => e.stopPropagation()}
                      >
                        {isFormMinimized(group.formId) ? <UnfoldMore fontSize="small" /> : <UnfoldLess fontSize="small" />}
                      </IconButton>
                    </Tooltip>
                  )}
                  
                  <Tooltip title="Calendar View">
                    <IconButton
                      size="small"
                      sx={{ display: { xs: 'none', sm: 'flex' } }} 
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/approval-calendar/${group.formId}`);
                      }}
                      onMouseDown={(e) => e.stopPropagation()}
                    >
                      <DateRange />
                    </IconButton>
                  </Tooltip>
                  
                  {/* Only show pending chip in the pending tab */}
                  {status === 'pending' && group.pendingCount > 0 && (
                    <Chip 
                      label={`${group.pendingCount} pending`} 
                      color="warning"
                      size="small"
                    />
                  )}
                </Box>
              </Box>
            </AccordionSummary>
            
            <AccordionDetails sx={{ p: 0 }}>
              <Box sx={{ 
                maxHeight: isFormMinimized(group.formId) ? '200px' : 'none',
                overflow: isFormMinimized(group.formId) ? 'auto' : 'visible',
                transition: 'max-height 0.3s ease-in-out'
              }}>
                <TableContainer sx={{ 
                  maxWidth: '100%',
                  overflow: 'auto',
                  '&::-webkit-scrollbar': {
                    height: '6px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    borderRadius: '3px',
                  }
                }}>
                  <Table size="medium">
                    <TableHead sx={{ bgcolor: theme.palette.background.default }}>
                      <TableRow>
                        <TableCell>Submitted By</TableCell>
                        <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Date</TableCell>
                        {status !== 'pending' && !isMobile && (
                          <TableCell>Comments</TableCell>
                        )}
                        <TableCell align="right">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getVisibleSubmissions(group).map((submission) => (
                        <TableRow key={submission.id}>
                          <TableCell>
                            <Typography variant="body2" sx={{ fontWeight: 500 }}>
                              {submission.email}
                            </Typography>
                            {isMobile && submission.submittedAt && (
                              <Typography variant="caption" color="text.secondary">
                                {typeof submission.submittedAt?.toDate === 'function' 
                                  ? formatDistanceToNow(submission.submittedAt.toDate(), { addSuffix: true })
                                  : 'Unknown date'
                                }
                                <br />
                                {typeof submission.submittedAt?.toDate === 'function'
                                  ? format(submission.submittedAt.toDate(), 'MMM d, yyyy')
                                  : 'Unknown Date'
                                }
                              </Typography>
                            )}
                            {userRole === "Admin" && !selectedUserId && (
                              <Box>
                                <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                                  Form created by: {
                                    users.find(u => u.id === submission.userId)?.email || 
                                    'Unknown user'
                                  }
                                </Typography>
                              </Box>
                            )}
                          </TableCell>
                          <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                            <Typography variant="body2">
                              {formatDate(submission.submittedAt)}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                              {submission.submittedAt && typeof submission.submittedAt?.toDate === 'function' && 
                                formatDistanceToNow(submission.submittedAt.toDate(), { addSuffix: true })}
                            </Typography>
                          </TableCell>
                          {status !== 'pending' && !isMobile && (
                            <TableCell>
                              {submission.approvalComments ? (
                                <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                                  "{submission.approvalComments}"
                                </Typography>
                              ) : (
                                <Typography variant="body2" color="text.secondary">
                                  No comments
                                </Typography>
                              )}
                            </TableCell>
                          )}
                          
                          <TableCell align="right">
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                              <Tooltip title="View Submission">
                                <IconButton 
                                  size="small" 
                                  onClick={(e) => {
                                    e.stopPropagation(); 
                                    onViewDetails(submission.id);
                                  }}
                                  onMouseDown={(e) => e.stopPropagation()}
                                >
                                  <Visibility />
                                </IconButton>
                              </Tooltip>
                              
                              {status === 'pending' && onApprove && onReject && (
                                <>
                                  <Tooltip title="Approve">
                                    <IconButton 
                                      size="small" 
                                      color="success" 
                                      onClick={(e) => {
                                        e.stopPropagation(); 
                                        onApprove(submission);
                                      }}
                                      onMouseDown={(e) => e.stopPropagation()}
                                    >
                                      <Done />
                                    </IconButton>
                                  </Tooltip>
                                  
                                  <Tooltip title="Reject">
                                    <IconButton 
                                      size="small" 
                                      color="error"
                                      onClick={(e) => {
                                        e.stopPropagation(); 
                                        onReject(submission);
                                      }}
                                      onMouseDown={(e) => e.stopPropagation()}
                                    >
                                      <Close />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              
              {isFormMinimized(group.formId) && (
                <Box 
                  sx={{ 
                    p: 1, 
                    display: 'flex', 
                    justifyContent: 'center',
                    borderTop: '1px dashed',
                    borderColor: 'divider',
                    backgroundColor: 'action.hover',
                    cursor: 'pointer'
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleMinimized(group.formId, e);
                  }}
                >
                  <Typography variant="caption" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                    <UnfoldMore fontSize="small" sx={{ mr: 0.5 }} /> 
                    Expand to see all {group.totalCount} submissions
                  </Typography>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  }
};

export default ApprovalSubmissionsList;

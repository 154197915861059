import { format } from 'date-fns';

interface PDFHeaderProps {
  doc: any;
  form: any;
  formId: string | undefined;
  formSlug: string | undefined;
  formTitle: string | undefined;
  submissionId?: string;
  responses: { [key: string]: any };
  logoUrl: string | null;
  primaryColorRgb: [number, number, number];
  lightGrayColor: [number, number, number];
  blackTextColor: [number, number, number];
}

export const PDFHeader = ({
  doc,
  form,
  formId,
  formSlug,
  formTitle,
  submissionId,
  responses,
  logoUrl,
  primaryColorRgb,
  lightGrayColor,
  blackTextColor
}: PDFHeaderProps): number => {
  
  // Add header with form title and date
  doc.setFillColor(primaryColorRgb[0], primaryColorRgb[1], primaryColorRgb[2]);
  doc.rect(0, 0, doc.internal.pageSize.getWidth(), 22, "F");
  
  // Add title
  doc.setFont("helvetica", "bold");
  doc.setTextColor(255, 255, 255);
  doc.setFontSize(16);
  doc.text(form.formTitle || "Filled Form", 10, 15);
  doc.setTextColor(0, 0, 0);
  
  // Add date
  const today = format(new Date(), 'dd MMMM yyyy');
  
  // If company logo is available, add it while preserving aspect ratio
  if (logoUrl && logoUrl.startsWith('data:image')) {
    // Create a temporary image to get dimensions
    const tempImg = new Image();
    tempImg.src = logoUrl;
    
    // Calculate aspect ratio and size for the logo
    const MAX_LOGO_WIDTH = 50;
    const MAX_LOGO_HEIGHT = 25;
    
    // Calculate dimensions that preserve aspect ratio
    let logoWidth = tempImg.width || 100;
    let logoHeight = tempImg.height || 50;
    
    // Adjust dimensions to fit within constraints while preserving aspect ratio
    if (logoWidth > logoHeight) {
      // Landscape orientation
      if (logoWidth > MAX_LOGO_WIDTH) {
        logoHeight = (logoHeight * MAX_LOGO_WIDTH) / logoWidth;
        logoWidth = MAX_LOGO_WIDTH;
      }
    } else {
      // Portrait orientation
      if (logoHeight > MAX_LOGO_HEIGHT) {
        logoWidth = (logoWidth * MAX_LOGO_HEIGHT) / logoHeight;
        logoHeight = MAX_LOGO_HEIGHT;
      }
    }
    
    // Position the logo on the right side
    const logoX = doc.internal.pageSize.getWidth() - logoWidth - 10;
    const logoY = 30;
    
    try {
      // Add image with calculated dimensions that preserve aspect ratio
      doc.addImage(logoUrl, "PNG", logoX, logoY, logoWidth, logoHeight);
    } catch (error) {
      console.error("Error adding logo to PDF:", error);
      // Fallback to company name if image fails
      if (form.companyName) {
        doc.setFont("helvetica", "bold");
        doc.setFontSize(12);
        doc.setTextColor(primaryColorRgb[0], primaryColorRgb[1], primaryColorRgb[2]);
        const companyNameX = doc.internal.pageSize.getWidth() - 10;
        doc.text(form.companyName, companyNameX, 40, { align: "right" });
        doc.setTextColor(0, 0, 0);
      }
    }
  } else if (form.companyName) {
    // Show company name if no logo
    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.setTextColor(primaryColorRgb[0], primaryColorRgb[1], primaryColorRgb[2]);
    const companyNameX = doc.internal.pageSize.getWidth() - 10;
    doc.text(form.companyName, companyNameX, 40, { align: "right" });
    doc.setTextColor(0, 0, 0);
  }
  
  // Add form information section - moved to left side and sized properly
  doc.setDrawColor(lightGrayColor[0], lightGrayColor[1], lightGrayColor[2]);
  doc.setFillColor(250, 250, 250);
  doc.roundedRect(10, 30, 100, 42, 3, 3, "FD"); 
  
  // Add form info text
  doc.setTextColor(blackTextColor[0], blackTextColor[1], blackTextColor[2]);
  doc.setFontSize(8); // Smaller font
  
  // Use formId or formSlug depending on what's available
  const identifierText = formSlug ? `Form Slug: ${formSlug}` : `Form ID: ${formId || "Unknown"}`;
  doc.text(identifierText, 15, 38);
  
  doc.text(`Form Title: ${formTitle || "Unknown"}`, 15, 46);
  doc.text(`Submitted on: ${today}`, 15, 54);
  doc.text(`Submitted by: ${responses.email || "Anonymous"}`, 15, 62);
  
  // Add submission ID if available
  if (submissionId) {
    doc.text(`Submission ID: ${submissionId}`, 15, 70);
    // Return a larger Y value to accommodate the extra line
    return 88;
  }
  
  // Return the current Y position after the header
  return 80;
};
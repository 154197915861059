import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  Box, Container, Typography, Paper, CircularProgress,
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, Divider, TextField, Alert, Chip, Tooltip,
  IconButton, useMediaQuery, ThemeProvider, MenuItem, Select, FormControl, InputLabel, OutlinedInput
} from '@mui/material';
import {
  CheckCircle, Cancel, ArrowBack, Done, Close,
  Event, Today, DateRange, Visibility, FilterList
} from '@mui/icons-material';
import { format, differenceInDays } from 'date-fns';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { EventInput, EventClickArg } from '@fullcalendar/core';
import { collection, query, where, getDocs, getDoc, doc, writeBatch, Timestamp, limit } from 'firebase/firestore';
import { db } from '../firebase';
import SharedNavBar from '../components/SharedNavBar';
import SubmissionDetail from '../components/SubmissionDetail/SubmissionDetail';
import { useUserPreferences } from '../hooks/useUserPreferences';
import { createAppTheme } from '../theme/themeOptions';
import { sendApprovalNotification } from '../utils/notificationHelpers';
import { processTimestamp, getStatusColor } from '../components/approval/services/approvalUtils';
import { getApprovalSubmissionDetails } from '../services/firebase/approvalService';
import EventDetailsModal from '../components/approval/EventDetailsModal/EventDetailsModal';

interface FormQuestion {
  id: string;
  type: string;
  label: string;
  fieldName?: string;
  required?: boolean;
}

interface DateEvent {
  id: string;
  submissionId: string;
  email: string;
  formId: string;
  formTitle: string;
  fieldName: string;
  startDate: Date;
  endDate: Date;
  questionText: string;
  status: 'pending' | 'approved' | 'rejected';
  comments?: string;
  submittedAt: Date;
  title: string;
  description?: string;
}

const ApprovalCalendarView: React.FC = () => {
  const { formId } = useParams<{ formId: string }>();
  const { user } = useUser();
  const navigate = useNavigate();

  const { theme: themePreference } = useUserPreferences();
  const theme = createAppTheme(themePreference || 'Blue');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState<any>(null);
  const [dateQuestions, setDateQuestions] = useState<FormQuestion[]>([]);
  const [calendarEvents, setCalendarEvents] = useState<EventInput[]>([]);
  const [dateEvents, setDateEvents] = useState<DateEvent[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<any | null>(null);
  const [eventModalOpen, setEventModalOpen] = useState(false);
  const [approvalDialogOpen, setApprovalDialogOpen] = useState(false);
  const [rejectionDialogOpen, setRejectionDialogOpen] = useState(false);
  const [comments, setComments] = useState('');
  const [processingAction, setProcessingAction] = useState(false);
  const [actionError, setActionError] = useState<string | null>(null);
  const [actionSuccess, setActionSuccess] = useState<string | null>(null);

  const [uniqueEmails, setUniqueEmails] = useState<string[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<string>('all');
  const [filteredEvents, setFilteredEvents] = useState<EventInput[]>([]);

  const [uniqueQuestions, setUniqueQuestions] = useState<Array<{ id: string, label: string }>>([]);
  const [selectedQuestionId, setSelectedQuestionId] = useState<string>('all');

  const [dataLoaded, setDataLoaded] = useState(false);
  const cachedDataRef = useRef<{
    formData: any;
    dateEvents: DateEvent[];
    calendarEvents: EventInput[];
    lastFetchTime: number;
  } | null>(null);

  const location = useLocation();

  useEffect(() => {
    if (user && formId) {
      loadFormAndDateSubmissions();
    }
  }, [user, formId]);

  const loadFormAndDateSubmissions = async (forceRefresh: boolean = false) => {
    try {
      const now = Date.now();
      if (
        !forceRefresh && 
        cachedDataRef.current &&
        (now - cachedDataRef.current.lastFetchTime < 5 * 60 * 1000)
      ) {
        console.log("Using cached calendar data");
        setForm(cachedDataRef.current.formData);
        setDateEvents(cachedDataRef.current.dateEvents);
        setCalendarEvents(cachedDataRef.current.calendarEvents);
        setFilteredEvents(cachedDataRef.current.calendarEvents);
        
        const emails = new Set<string>();
        cachedDataRef.current.dateEvents.forEach(event => {
          if (event.email) emails.add(event.email);
        });
        setUniqueEmails(Array.from(emails).sort());
        
        const questions = new Map<string, { id: string, label: string }>();
        cachedDataRef.current.dateEvents.forEach(event => {
          if (!questions.has(event.fieldName)) {
            questions.set(event.fieldName, {
              id: event.fieldName,
              label: event.questionText || 'Unnamed Question'
            });
          }
        });
        setUniqueQuestions(Array.from(questions.values())
          .sort((a, b) => a.label.localeCompare(b.label)));
        
        setDataLoaded(true);
        return;
      }
      
      setLoading(true);
      
      const formRef = doc(db, "forms", formId || '');
      const formSnap = await getDoc(formRef);
      
      if (!formSnap.exists()) {
        setActionError("Form not found.");
        setLoading(false);
        return;
      }
      
      const formData = formSnap.data();
      setForm(formData);
      
      console.log("Finding date fields in form...");
      const dateFieldQuestions = (formData.questions || []).filter((q: any) => 
        q.type === 'date' || 
        q.type === 'dateRange' || 
        q.type === 'date-range' ||
        q.type === 'time' ||
        q.type === 'datetime'
      );
      
      const dateFieldIds = dateFieldQuestions.map((q: any) => q.id);
      
      if (!dateFieldIds.includes('28048bd2-847b-4e11-b949-31e12c164ee7')) {
        console.log("Adding problematic date range ID manually");
        dateFieldIds.push('28048bd2-847b-4e11-b949-31e12c164ee7');
      }
      
      setDateQuestions(dateFieldQuestions);
      
      const submissionsQuery = query(
        collection(db, "submission_details"),
        where("formId", "==", formId || ''),
        where("archived", "==", false)
      );
      
      const submissionsSnapshot = await getDocs(submissionsQuery);
      console.log(`Found ${submissionsSnapshot.docs.length} submissions to process...`);
      
      if (submissionsSnapshot.docs.length === 0) {
        setDateEvents([]);
        setCalendarEvents([]);
        setFilteredEvents([]);
        setUniqueEmails([]);
        setUniqueQuestions([]);
        setLoading(false);
        setDataLoaded(true);
        return;
      }
      
      const eventsData: DateEvent[] = [];
      const addedEventIds = new Map<string, boolean>();
      
      const batchSize = 20;
      const totalSubmissions = submissionsSnapshot.docs.length;
      
      for (let i = 0; i < totalSubmissions; i += batchSize) {
        const batchEnd = Math.min(i + batchSize, totalSubmissions);
        console.log(`Processing submissions ${i+1}-${batchEnd} of ${totalSubmissions}`);
        
        if (i > 0) {
          await new Promise(resolve => setTimeout(resolve, 10));
        }
        
        const batchDocs = submissionsSnapshot.docs.slice(i, batchEnd);
        
        await new Promise(resolve => setTimeout(resolve, 0));
        
        for (const submissionDoc of batchDocs) {
          const submissionData = submissionDoc.data();
          
          let hasDateField = false;
          const responses = submissionData.responses || {};
          
          for (const fieldId of dateFieldIds) {
            if (responses[fieldId]) {
              hasDateField = true;
              break;
            }
          }
          
          if (!hasDateField) continue;
          
          for (const fieldId of dateFieldIds) {
            if (!responses[fieldId]) continue;
            
            try {
              const questionData = dateFieldQuestions.find((q: any) => q.id === fieldId) || {
                label: 'Date Question',
                type: 'date'
              };
              
              if (questionData.type === 'date-range' || questionData.type === 'dateRange') {
                const rangeData = responses[fieldId];
                let startDate = null;
                let endDate = null;
                
                if (typeof rangeData === 'object' && rangeData !== null) {
                  if (rangeData.start && rangeData.end) {
                    startDate = processTimestamp(rangeData.start);
                    endDate = processTimestamp(rangeData.end);
                  } else if (rangeData.startDate && rangeData.endDate) {
                    startDate = processTimestamp(rangeData.startDate);
                    endDate = processTimestamp(rangeData.endDate);
                  } else if (Array.isArray(rangeData) && rangeData.length >= 2) {
                    startDate = processTimestamp(rangeData[0]);
                    endDate = processTimestamp(rangeData[1]);
                  }
                }
                
                if (startDate && endDate) {
                  const eventId = `${submissionDoc.id}-${fieldId}`;
                  
                  if (!addedEventIds.has(eventId)) {
                    eventsData.push({
                      id: eventId,
                      submissionId: submissionDoc.id,
                      email: submissionData.email || 'Anonymous',
                      formId: formId || '',
                      formTitle: formData.formTitle || 'Untitled Form',
                      fieldName: fieldId,
                      startDate: startDate,
                      endDate: endDate,
                      questionText: questionData.label || 'Date Range',
                      status: submissionData.approvalStatus || 'pending',
                      comments: submissionData.approvalComments,
                      submittedAt: processTimestamp(submissionData.submittedAt) || new Date(),
                      title: `${submissionData.email}: ${questionData.label || 'Date Range'}`,
                      description: `${questionData.label || 'Date Range'} from ${format(startDate, 'MMM d, yyyy')} to ${format(endDate, 'MMM d, yyyy')}`
                    });
                    
                    addedEventIds.set(eventId, true);
                  }
                }
              } else {
                const dateValue = processTimestamp(responses[fieldId]);
                
                if (dateValue) {
                  const eventId = `${submissionDoc.id}-${fieldId}`;
                  
                  if (!addedEventIds.has(eventId)) {
                    const endDate = new Date(dateValue);
                    endDate.setDate(endDate.getDate() + 1);
                    
                    eventsData.push({
                      id: eventId,
                      submissionId: submissionDoc.id,
                      email: submissionData.email || 'Anonymous',
                      formId: formId || '',
                      formTitle: formData.formTitle || 'Untitled Form',
                      fieldName: fieldId,
                      startDate: dateValue,
                      endDate: endDate,
                      questionText: questionData.label || 'Date',
                      status: submissionData.approvalStatus || 'pending',
                      comments: submissionData.approvalComments,
                      submittedAt: processTimestamp(submissionData.submittedAt) || new Date(),
                      title: `${submissionData.email}: ${questionData.label || 'Date'}`,
                      description: `${questionData.label || 'Date'}: ${format(dateValue, 'MMM d, yyyy')}`
                    });
                    
                    addedEventIds.set(eventId, true);
                  }
                }
              }
            } catch (error) {
              console.error(`Error processing submission ${submissionDoc.id}:`, error);
            }
          }
        }
      }
      
      console.log(`Total events found: ${eventsData.length}`);
      
      if (eventsData.length === 0) {
        console.log("No events found - adding a test event");
        
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        
        eventsData.push({
          id: 'test-event',
          submissionId: 'test-submission',
          email: 'test@example.com',
          formId: formId || '',
          formTitle: formData.formTitle || 'Untitled Form',
          fieldName: 'test-field',
          startDate: today,
          endDate: tomorrow,
          questionText: 'Test Date Question',
          status: 'pending',
          comments: '',
          submittedAt: today,
          title: 'Test Event: Date Range',
          description: `Test Date Range from ${format(today, 'MMM d, yyyy')} to ${format(tomorrow, 'MMM d, yyyy')}`
        });
        
        console.log("Added test event for debugging:", eventsData[0]);
      }
      
      setDateEvents(eventsData);
      
      const validEvents = eventsData.filter(event => {
        if (!event.id || !event.title) return false;
        if (!event.startDate || !event.endDate) return false;
        if (!(event.startDate instanceof Date) || !(event.endDate instanceof Date)) return false;
        if (isNaN(event.startDate.getTime()) || isNaN(event.endDate.getTime())) return false;
        return true;
      });
      
      const fullCalendarEvents = validEvents.map(event => {
        const startStr = event.startDate.toISOString();
        const endDate = new Date(event.endDate);
        endDate.setDate(endDate.getDate() + 1);
        const endStr = endDate.toISOString();
        
        return {
          id: event.id,
          title: event.title,
          start: startStr,
          end: endStr,
          allDay: true,
          extendedProps: {
            status: event.status,
            email: event.email,
            description: event.description,
            submissionId: event.submissionId,
            fieldName: event.fieldName
          },
          backgroundColor: getStatusColor(event.status),
          borderColor: getStatusColor(event.status, true),
          textColor: '#000000'
        };
      });
      
      setCalendarEvents(fullCalendarEvents);
      
      const emails = new Set<string>();
      validEvents.forEach(event => {
        if (event.email && typeof event.email === 'string') {
          emails.add(event.email);
        }
      });
      
      setUniqueEmails(Array.from(emails).sort());
      
      const questions = new Map<string, { id: string, label: string }>();
      dateFieldQuestions.forEach((q: FormQuestion) => {
        questions.set(q.id, {
          id: q.id,
          label: q.label || 'Unnamed Question'
        });
      });
      
      validEvents.forEach(event => {
        if (event.fieldName && !questions.has(event.fieldName)) {
          questions.set(event.fieldName, {
            id: event.fieldName,
            label: event.questionText || 'Unnamed Question'
          });
        }
      });
      
      setUniqueQuestions(Array.from(questions.values()).sort((a, b) =>
        a.label.localeCompare(b.label)
      ));
      
      cachedDataRef.current = {
        formData,
        dateEvents: eventsData,
        calendarEvents: fullCalendarEvents,
        lastFetchTime: Date.now()
      };
    } catch (error) {
      console.error("Error loading form data or submissions:", error);
      setActionError("Failed to load calendar data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Add this effect for background loading while on manager page
  useEffect(() => {
    // This effect is for preloading data in background
    const location = window.location.pathname;
    const isOnManagerPage = location.includes('approval-manager');
    
    if (isOnManagerPage && formId && !dataLoaded && !cachedDataRef.current) {
      console.log("Pre-loading calendar data in background while on manager page");
      
      // Only load form and date field definitions, not all submissions
      // This is a lightweight background operation
      (async () => {
        try {
          // Get form data
          const formRef = doc(db, "forms", formId);
          const formSnap = await getDoc(formRef);
          
          if (!formSnap.exists()) return;
          
          const formData = formSnap.data();
          
          // Parse date fields
          const dateFieldQuestions = (formData.questions || []).filter((q: any) => 
            q.type === 'date' || 
            q.type === 'dateRange' || 
            q.type === 'date-range' ||
            q.type === 'time' ||
            q.type === 'datetime'
          );
          
          // Store in cache with empty events (will be populated when actually viewing)
          cachedDataRef.current = {
            formData,
            dateEvents: [],
            calendarEvents: [],
            lastFetchTime: 0 // Set to 0 to force reload when actually visiting
          };
          
          console.log("Pre-loaded form data for calendar");
        } catch (error) {
          console.error("Error pre-loading calendar data:", error);
        }
      })();
    }
  }, [formId, dataLoaded]);

  const handleEventClick = (clickInfo: EventClickArg) => {
    setSelectedEvent(clickInfo.event);
    setEventModalOpen(true);
    console.log('Event clicked:', clickInfo.event);
    console.log('Submission ID:', clickInfo.event.extendedProps?.submissionId);
  };

  const handleEventStatusChange = () => {
    setTimeout(() => {
      loadFormAndDateSubmissions();
    }, 1000);
  };

  const handleBackToApprovals = () => {
    navigate('/approval-manager');
  };

  const applyFilters = useCallback(() => {
    if (!calendarEvents.length) {
      setFilteredEvents([]);
      return;
    }

    let filtered = [...calendarEvents];

    if (selectedEmail !== 'all') {
      filtered = filtered.filter(event => {
        const email = event.extendedProps?.email;
        return email === selectedEmail;
      });
    }

    if (selectedQuestionId !== 'all') {
      filtered = filtered.filter(event => {
        const fieldName = event.extendedProps?.fieldName;
        return fieldName === selectedQuestionId;
      });
    }

    setFilteredEvents(filtered);
  }, [calendarEvents, selectedEmail, selectedQuestionId]);

  useEffect(() => {
    applyFilters();
  }, [applyFilters, calendarEvents, selectedEmail, selectedQuestionId]);

  const pendingEvents = filteredEvents.filter(e => e.extendedProps?.status === 'pending').length;
  const approvedEvents = filteredEvents.filter(e => e.extendedProps?.status === 'approved').length;
  const rejectedEvents = filteredEvents.filter(e => e.extendedProps?.status === 'rejected').length;

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: theme.palette.background.default, minHeight: '100vh' }}>
        <SharedNavBar title="Calendar Approval View" />

        <Container maxWidth="lg" sx={{ mt: { xs: 2, sm: 4 }, mb: 8 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, flexWrap: 'wrap', gap: 2 }}>
            <IconButton
              onClick={handleBackToApprovals}
              sx={{ mr: 1 }}
            >
              <ArrowBack />
            </IconButton>

            <Typography variant="h5" component="h1">
              {form?.formTitle || "Calendar View"}
            </Typography>

            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ display: 'flex', gap: 1 }}>
              <Tooltip title="Pending Approvals">
                <Chip
                  icon={<Event />}
                  label={`${pendingEvents} Pending`}
                  color="warning"
                />
              </Tooltip>

              <Tooltip title="Approved">
                <Chip
                  icon={<CheckCircle />}
                  label={`${approvedEvents} Approved`}
                  color="success"
                />
              </Tooltip>

              <Tooltip title="Rejected">
                <Chip
                  icon={<Cancel />}
                  label={`${rejectedEvents} Rejected`}
                  color="error"
                />
              </Tooltip>
            </Box>
          </Box>

          <Paper
            sx={{
              p: 2,
              mb: 3,
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              gap: 2,
              borderRadius: 2,
              border: '1px solid',
              borderColor: 'divider'
            }}
          >
            <Box display="flex" alignItems="center">
              <FilterList sx={{ color: 'text.secondary', mr: 1 }} />
              <Typography variant="subtitle2" color="text.secondary">
                Filters:
              </Typography>
            </Box>

            <FormControl size="small" sx={{ minWidth: 200, flexGrow: { xs: 1, sm: 0 } }}>
              <InputLabel id="email-filter-label">Requester</InputLabel>
              <Select
                labelId="email-filter-label"
                id="email-filter"
                value={selectedEmail}
                onChange={(e) => setSelectedEmail(e.target.value as string)}
                input={<OutlinedInput label="Requester" />}
              >
                <MenuItem value="all">All Requesters</MenuItem>
                {uniqueEmails.map(email => (
                  <MenuItem key={email} value={email}>
                    {email}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl size="small" sx={{ minWidth: 220, flexGrow: { xs: 1, sm: 0 } }}>
              <InputLabel id="question-filter-label">Date Question</InputLabel>
              <Select
                labelId="question-filter-label"
                id="question-filter"
                value={selectedQuestionId}
                onChange={(e) => setSelectedQuestionId(e.target.value as string)}
                input={<OutlinedInput label="Date Question" />}
              >
                <MenuItem value="all">All Date Questions</MenuItem>
                {uniqueQuestions.map(question => (
                  <MenuItem key={question.id} value={question.id}>
                    {question.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {selectedEmail !== 'all' && (
              <Chip
                label={`From: ${selectedEmail}`}
                onDelete={() => setSelectedEmail('all')}
                color="primary"
                variant="outlined"
                size="small"
              />
            )}

            {selectedQuestionId !== 'all' && (
              <Chip
                label={`Question: ${uniqueQuestions.find(q => q.id === selectedQuestionId)?.label || selectedQuestionId}`}
                onDelete={() => setSelectedQuestionId('all')}
                color="primary"
                variant="outlined"
                size="small"
              />
            )}

            <Box sx={{ flexGrow: 1 }} />

            <Typography variant="body2" color="text.secondary">
              {filteredEvents.length} {filteredEvents.length === 1 ? 'event' : 'events'} displayed
            </Typography>
          </Paper>

          <Alert severity="info" sx={{ mb: 3 }}>
            <Typography variant="body2">
              Click on any date in the calendar to view details and manage approvals.
              Different colors indicate status: orange for pending, green for approved, and red for rejected items.
            </Typography>
          </Alert>

          {isMobile && (
            <Alert
              severity="info"
              sx={{ mb: 2 }}
              onClose={() => { }}
            >
              <Typography variant="body2">
                For a better calendar experience, try landscape mode or use a larger screen.
              </Typography>
            </Alert>
          )}

          {actionError && (
            <Alert
              severity="error"
              sx={{ mb: 2 }}
              onClose={() => setActionError(null)}
            >
              {actionError}
            </Alert>
          )}

          {actionSuccess && (
            <Alert
              severity="success"
              sx={{ mb: 2 }}
              onClose={() => setActionSuccess(null)}
            >
              {actionSuccess}
            </Alert>
          )}

          <Paper
            elevation={0}
            sx={{
              p: { xs: 1, sm: 2 },
              borderRadius: 2,
              border: '1px solid',
              borderColor: 'divider',
              overflow: 'hidden'
            }}
          >
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{
                '.fc .fc-toolbar-title': {
                  fontSize: { xs: '1.2rem', sm: '1.5rem' }
                },
                '.fc .fc-button': {
                  textTransform: 'capitalize',
                  fontWeight: 'normal'
                },
                '.fc .fc-daygrid-day-number': {
                  fontSize: '0.9rem',
                  padding: '4px 8px'
                },
                '.fc .fc-event': {
                  cursor: 'pointer'
                }
              }}>
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                  initialView={isMobile ? "listWeek" : "dayGridMonth"}
                  headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: isMobile ? 'listWeek,dayGridMonth' : 'dayGridMonth,timeGridWeek,listWeek'
                  }}
                  events={filteredEvents.length > 0 ? filteredEvents : []}
                  eventClick={handleEventClick}
                  height="auto"
                  aspectRatio={1.8}
                  stickyHeaderDates={true}
                  timeZone="local"
                  eventTimeFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: 'short'
                  }}
                  views={{
                    listWeek: {
                      listDayFormat: { weekday: 'long' },
                      listDayAltFormat: { month: 'long', day: 'numeric' }
                    }
                  }}
                />
              </Box>
            )}
          </Paper>
        </Container>

        <EventDetailsModal
          open={eventModalOpen}
          onClose={() => setEventModalOpen(false)}
          event={selectedEvent}
          onStatusChange={handleEventStatusChange}
        />
      </Box>
    </ThemeProvider>
  );
};

export default ApprovalCalendarView;
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Box, Typography, Container, CircularProgress, 
  Paper, MenuItem, FormControl, InputLabel, Select,
  Button, Accordion, AccordionSummary, AccordionDetails,
  SelectChangeEvent
} from '@mui/material';
import { 
  collection, query, where, getDocs, 
  getDoc, doc, setDoc 
} from 'firebase/firestore';
import { db } from '../firebase';
import { createAppTheme } from '../theme/themeOptions';
import { ExpandMore } from '@mui/icons-material';
import { ThemeProvider } from "@mui/material/styles";

interface FAQ {
  id: string;
  question: string;
  answer: string;
}

interface FormWithFAQ {
  id: string;
  title: string;
  description?: string;
  faqs: FAQ[];
}

interface LandingPageData {
  userId: string;
  title: string;
  description: string;
  companyName: string;
  companyLogo?: string;
  companySlug: string;
  isActive: boolean;
  forms: FormWithFAQ[];
  theme?: string;
}

const CompanyLandingPage: React.FC = () => {
  const { companySlug } = useParams<{ companySlug: string }>();
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [landingPage, setLandingPage] = useState<LandingPageData | null>(null);
  const [selectedFormId, setSelectedFormId] = useState('');
  const [selectedForm, setSelectedForm] = useState<FormWithFAQ | null>(null);
  const [pageTheme, setPageTheme] = useState<string>("Blue");
  
  const loadLandingPage = async () => {
    if (!companySlug) {
      setNotFound(true);
      setLoading(false);
      return;
    }
    
    try {
      setLoading(true);
      console.log("Loading landing page for slug:", companySlug);
      
      let userQuery = query(
        collection(db, "users"),
        where("shortCompanyName", "==", companySlug)
      );
      
      let userSnapshot = await getDocs(userQuery);
      
      if (userSnapshot.empty) {
        userQuery = query(
          collection(db, "users"),
          where("companySlug", "==", companySlug)
        );
        userSnapshot = await getDocs(userQuery);
      }
      
      let landingPageData: any = null;
      let userId: string | null = null;
      let userTheme: string = 'Blue';
      
      if (!userSnapshot.empty) {
        console.log("Found user with matching company name");
        const userDoc = userSnapshot.docs[0];
        userId = userDoc.id;
        const userData = userDoc.data();
        
        userTheme = userData.theme || 'Blue';
        
        console.log("Checking for landing page for user:", userId);
        const landingPageDoc = await getDoc(doc(db, "landing_pages", userId));
        
        if (landingPageDoc.exists()) {
          console.log("Landing page found for user");
          landingPageData = landingPageDoc.data() as LandingPageData;
          
          landingPageData.companyName = userData.companyName || landingPageData.companyName;
          landingPageData.companyLogo = userData.companyLogo || landingPageData.companyLogo;
          
          landingPageData.theme = landingPageData.theme || userTheme;
          setPageTheme(landingPageData.theme || userTheme);
        } else {
          console.log("No landing page for this user");
          setNotFound(true);
          setLoading(false);
          return;
        }
      } else {
        console.log("No user found with matching shortCompanyName/companySlug, trying landing_pages directly");
        const landingPagesQuery = query(
          collection(db, "landing_pages"),
          where("companySlug", "==", companySlug),
          where("isActive", "==", true)
        );
        
        const landingPagesSnapshot = await getDocs(landingPagesQuery);
        console.log("Landing pages query result size:", landingPagesSnapshot.size);
        
        if (landingPagesSnapshot.empty) {
          console.log("No landing page found with matching companySlug");
          setNotFound(true);
          setLoading(false);
          return;
        }
        
        landingPageData = landingPagesSnapshot.docs[0].data() as LandingPageData;
        userId = landingPageData.userId;
        console.log("Found landing page with userId:", userId);
        
        if (userId) {
          const userDoc = await getDoc(doc(db, "users", userId));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            landingPageData.companyName = userData.companyName || landingPageData.companyName;
            landingPageData.companyLogo = userData.companyLogo || landingPageData.companyLogo;
            
            userTheme = userData.theme || 'Blue';
            landingPageData.theme = landingPageData.theme || userTheme;
            setPageTheme(landingPageData.theme || userTheme);
          }
        }
      }

      if (landingPageData.forms && landingPageData.forms.length > 0) {
        console.log("Processing forms for landing page, count:", landingPageData.forms.length);
        const formPromises = landingPageData.forms.map(async (form: FormWithFAQ) => {
          if (!form.title || form.title === 'Untitled Form') {
            try {
              const formDoc = await getDoc(doc(db, "forms", form.id));
              if (formDoc.exists()) {
                const formData = formDoc.data();
                return {
                  ...form,
                  title: formData.formTitle || formData.title || form.title || 'Untitled Form',
                  description: formData.description || formData.formDescription || form.description || ''
                };
              }
            } catch (error) {
              console.error(`Error fetching form ${form.id}:`, error);
            }
          }
          return form;
        });
        
        const updatedForms = await Promise.all(formPromises);
        landingPageData.forms = updatedForms;
        console.log("Forms processed successfully");
      }

      if (landingPageData.forms && landingPageData.forms.length === 1) {
        console.log("Only one form available, selecting it automatically");
        setSelectedFormId(landingPageData.forms[0].id);
        setSelectedForm(landingPageData.forms[0]);
      }
      
      console.log("Setting landing page data");
      setLandingPage(landingPageData);
    } catch (error) {
      console.error("Error loading landing page:", error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    loadLandingPage();
  }, [companySlug]);
  
  const handleFormChange = (event: SelectChangeEvent) => {
    const formId = event.target.value;
    setSelectedFormId(formId);
    
    const form = landingPage?.forms.find(f => f.id === formId);
    setSelectedForm(form || null);
  };
  
  const handleGoToForm = async () => {
    if (selectedFormId && landingPage) {
      try {
        // Fetch the current form data to ensure we have the latest slug
        const formDoc = await getDoc(doc(db, "forms", selectedFormId));
        
        if (!formDoc.exists()) {
          // If form doesn't exist, use the fallback approach
          navigate(`/fill/${selectedFormId}`);
          return;
        }
        
        // Get the latest form data
        const formData = formDoc.data();
        const formSlug = formData.formSlug;
        
        if (landingPage.companySlug && formSlug) {
          // Use the canonical URL format if we have both slugs
          navigate(`/${landingPage.companySlug}/${formSlug}`);
        } else {
          // Fallback to direct form ID approach
          navigate(`/fill/${selectedFormId}`);
        }
      } catch (error) {
        console.error("Error navigating to form:", error);
        // Fallback approach
        navigate(`/fill/${selectedFormId}`);
      }
    }
  };
  
  const theme = createAppTheme(pageTheme);
  
  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        height: '100vh' 
      }}>
        <CircularProgress />
      </Box>
    );
  }
  
  if (notFound) {
    return (
      <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 8 }}>
        <Typography variant="h4" gutterBottom>
          Page Not Found
        </Typography>
        <Typography variant="body1" paragraph>
          The landing page you're looking for doesn't exist or is not active.
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => navigate('/')}
        >
          Go to Homepage
        </Button>
      </Container>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ 
        minHeight: '100vh',
        backgroundColor: '#ffffff',
        pt: 4,
        pb: 8
      }}>
        <Container maxWidth="md">
          <Paper 
            elevation={0} 
            sx={{ 
              p: { xs: 3, sm: 4 },
              borderRadius: 2,
              textAlign: 'center',
              mb: 4,
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              boxShadow: '0 4px 20px rgba(0,0,0,0.08)'
            }}
          >
            {landingPage?.companyLogo && (
              <Box sx={{ 
                mb: 3, 
                display: 'flex', 
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100px' // Fixed container height
              }}>
                <img 
                  src={landingPage.companyLogo} 
                  alt={landingPage.companyName || "Company Logo"}
                  style={{ 
                    maxHeight: '100%',
                    maxWidth: '100%',
                    objectFit: 'contain', // This preserves aspect ratio
                    padding: '0 16px'
                  }}
                />
              </Box>
            )}
            
            <Typography 
              variant="h3" 
              component="h1" 
              gutterBottom
              sx={{ fontSize: { xs: '2rem', sm: '3rem' } }}
            >
              {landingPage?.title || 'Select a Form'}
            </Typography>
            
            <Typography variant="body1" paragraph>
              {landingPage?.description || 'Please select a form to fill out.'}
            </Typography>
          </Paper>
          
          <Paper 
            elevation={0} 
            sx={{ 
              p: { xs: 3, sm: 4 },
              borderRadius: 2,
              mb: 4,
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              boxShadow: '0 4px 20px rgba(0,0,0,0.08)'
            }}
          >
            {landingPage?.forms?.length === 0 ? (
              <Typography variant="body1" color="text.secondary" align="center">
                No forms are currently available.
              </Typography>
            ) : (
              <>
                {(landingPage?.forms ?? []).length > 1 ? (
                  <Box sx={{ mb: 4 }}>
                    <FormControl fullWidth>
                      <InputLabel id="form-select-label">Choose a form</InputLabel>
                      <Select
                        labelId="form-select-label"
                        value={selectedFormId}
                        label="Choose a form"
                        onChange={handleFormChange}
                      >
                        <MenuItem value="" disabled>
                          <em>Select a form</em>
                        </MenuItem>
                        {landingPage?.forms?.map(form => (
                          <MenuItem key={form.id} value={form.id}>
                            {form.title || 'Untitled Form'}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                ) : (
                  <Typography variant="h6" gutterBottom>
                    {landingPage?.forms[0]?.title || 'Untitled Form'}
                  </Typography>
                )}
                
                {selectedForm && (
                  <Box>
                    {selectedForm.description && (
                      <Typography variant="body1" paragraph>
                        {selectedForm.description}
                      </Typography>
                    )}
                    
                    {selectedForm.faqs && selectedForm.faqs.length > 0 && (
                      <Box sx={{ mt: 4, mb: 4 }}>
                        <Typography variant="h6" gutterBottom>Frequently Asked Questions</Typography>
                        
                        <Typography 
                          variant="subtitle2" 
                          sx={{ 
                            mb: 2, 
                            fontStyle: 'italic', 
                            color: 'text.secondary',
                            bgcolor: 'rgba(0, 0, 0, 0.02)',
                            p: 1.5,
                            borderLeft: `4px solid ${theme.palette.primary.main}`,
                            borderRadius: '0 4px 4px 0'
                          }}
                        >
                          Please review all FAQ questions before submitting your form to ensure you have all the necessary information.
                        </Typography>
                        
                        {selectedForm.faqs.map((faq) => (
                          <Accordion key={faq.id} sx={{ mb: 1, boxShadow: 'none', border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                            <AccordionSummary 
                              expandIcon={<ExpandMore />}
                              sx={{ 
                                bgcolor: 'rgba(0, 0, 0, 0.02)',
                                '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' } 
                              }}
                            >
                              <Typography fontWeight="500">{faq.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>{faq.answer}</Typography>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </Box>
                    )}
                    
                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleGoToForm}
                      >
                        Continue to Form
                      </Button>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Paper>
          
          <Box sx={{ 
            mt: 8, 
            textAlign: 'center'
          }}>
            <Typography variant="body2" color="text.secondary">
              {landingPage?.companyName || 'Company'} © {new Date().getFullYear()}
            </Typography>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default CompanyLandingPage;

function syncProfileToLandingPage(userId: string) {
  const syncProfileData = async (userId: string): Promise<boolean> => {
    try {
      const userDoc = await getDoc(doc(db, "users", userId));
      
      if (!userDoc.exists()) {
        return false;
      }
      
      const userData = userDoc.data();
      
      const landingPageRef = doc(db, "landing_pages", userId);
      const landingPageSnap = await getDoc(landingPageRef);
      
      if (!landingPageSnap.exists()) {
        return false;
      }
      
      await setDoc(landingPageRef, {
        companyName: userData.companyName || '',
        companyLogo: userData.companyLogo || null,
        companySlug: userData.shortCompanyName || landingPageSnap.data().companySlug,
        theme: userData.theme || landingPageSnap.data().theme || 'Blue',
        updatedAt: new Date()
      }, { merge: true });
      
      return true;
    } catch (error) {
      console.error("Error synchronizing profile data:", error);
      return false;
    }
  };
  
  return syncProfileData(userId);
}

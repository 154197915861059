import { useState } from 'react';
import { PDFDocument } from '../components/PDFGeneration';
import { getImageDimensionsFromBase64 } from '../utils/imageHelpers';

export const usePdfGenerator = () => {
  const [pdfGenerating, setPdfGenerating] = useState<boolean>(false);

  const generatePdf = async (
    formId: string,
    form: any,
    responses: { [key: string]: any },
    repeatableItems: { [key: string]: Array<{ [key: string]: any }> },
    signatures: { [key: string]: string },
    imagePreview: { [key: string]: string },
    annotatedImages: { [key: string]: string },
    logoUrl: string | null,
    theme: any,
    submissionId?: string,
    followUpResponses?: Record<string, string>
  ) => {
    setPdfGenerating(true);
    
    try {
      // Merge followUpResponses with regular responses if provided
      const mergedResponses = { ...responses };
      
      if (followUpResponses) {
        Object.keys(followUpResponses).forEach(key => {
          mergedResponses[key] = followUpResponses[key];
        });
      }
      
      // Pre-process logo to ensure it has proper dimensions for PDF
      let processedLogoUrl = logoUrl;
      
      if (logoUrl && logoUrl.startsWith('data:image')) {
        try {
          // Get dimensions to check if we need to adjust
          const dimensions = await getImageDimensionsFromBase64(logoUrl);
          console.log("Logo dimensions:", dimensions);
          
          // Log for debugging
          if (dimensions.width > 800 || dimensions.height > 600) {
            console.log("Logo is large, may need optimization for PDF");
          }
        } catch (err) {
          console.error("Error checking logo dimensions:", err);
        }
      }
      
      // Use our new PDFDocument component to create the PDF
      const doc = PDFDocument({
        formId,
        form,
        responses: mergedResponses,
        repeatableItems: repeatableItems || {}, // Ensure it's never undefined
        signatures,
        imagePreview,
        annotatedImages,
        logoUrl: processedLogoUrl,
        theme,
        submissionId: submissionId || ''
      });
      
      setPdfGenerating(false);
      return doc;
    } catch (error) {
      console.error('Error generating PDF:', error);
      setPdfGenerating(false);
      throw error;
    }
  };

  return { generatePdf, pdfGenerating };
};
